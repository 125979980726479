import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import Header from "../components/Header";
import Layout from "../components/layout";
import ArrowDown from "../lotties/arrow-down";
import GoOn from "../lotties/careers-go-on.json";
import styles from "../styles/careers.module.scss";

const downArrow = {
  loop: true,
  autoplay: true,
  animationData: ArrowDown,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const goOn = {
  loop: true,
  autoplay: true,
  animationData: GoOn,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Careers = () => {
  // to focus the div element containing the contents
  const ready = () => {
    document.getElementsByClassName(styles.careers)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="Careers | Mahusai"
      description="We are currently looking for developers and designers who are hard working and crazy talented."
      pathname="/careers/"
    >
      <div
        className={styles.careers}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Header />

        <Container fluid>
          <Row xs={1} className={styles.row + " " + styles.topRow}>
            {/* <Col lg={{ span: 7 }}>
              <h1 className={styles.hello}>Hello.</h1>
            </Col> */}
            <Col xs md lg={{ span: 7 }} className="col-height-mobile">
              <div className="details-section center-middle-position">
                <div className="title">Hello.</div>
              </div>
            </Col>
            <Col lg={{ span: 5 }} className={styles.bgBdazzledBlue}>
              <div className={styles.details}>
                <p>
                  We are currently looking for{" "}
                  <span className={styles.green}>developers</span> and{" "}
                  <span className={styles.green}>designers</span> who are hard
                  working and crazy talented.
                </p>
                <p>Up for the challenge?</p>
                <p>
                  Shoot us an email with a link to your portfolio along with a
                  little something about yourself.
                </p>
              </div>
            </Col>
          </Row>
          <Row xs={1} className={styles.row}>
            <Col lg={{ span: 7 }} className={styles.bgBdazzledBlue}>
              <div className={`${styles.arrowDown} ${styles.centerMiddle}`}>
                <Lottie options={goOn} width="47vw" height="47vw" />
              </div>
            </Col>
            <Col
              lg={{ span: 5 }}
              className={`${styles.bgColumbiaBlue} text-center`}
            >
              <div className={`${styles.details} ${styles.detailsSmallScreen}`}>
                <p className={styles.sendEmail}>Email us here.</p>
                <Lottie options={downArrow} height="12vh" width="12vh" />
                <a
                  href="mailto:hello@mahusai.ph"
                  target="_blank"
                  className={styles.contactUsLink}
                  rel="noreferrer"
                >
                  <p className={styles.email}>
                    hello@
                    <br />
                    mahus
                    <br />
                    ai.ph
                  </p>
                </a>
                <p className={styles.copy}>COPYRIGHT 2020 • MAHUSAI INC.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Careers;
